const config = {
  ENVIRONMENT_NAME: 'dev',
  forwoodId: {
    URL: 'https://id.dev.droopy.forwoodsafety.com',
    APP_CLIENT_ID: '3d8t853pe6db4q9p4jvldbhfj0',
    APIGATEWAY: 'https://caju1r7ht8.execute-api.us-west-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://cyixw4k6l2.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    ENVIRONMENT_NAME: 'dev',
    VERSION: 'resources-container-1.7.0',
    HOSTNAME: 'https://resources.dev.droopy.forwoodsafety.com',
    TRAINING_MATERIALS_URL: 'https://iframe.dev.droopy.forwoodsafety.com/training-materials',
    OFFICIAL_CRM_ICONS_URL: 'https://iframe.dev.droopy.forwoodsafety.com/risk-icons',
    PRESENTATION_IFRAME_URL: 'https://iframe.dev.droopy.forwoodsafety.com/node/11990',
    IFRAME_ORIGIN_URL: 'https://iframe.dev.droopy.forwoodsafety.com'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
